import { CardVariants } from "@nextui-org/react";
import toast from "react-hot-toast";

export function getCardVariant(): CardVariants {
	return "bordered";
}

export function formatLink(value: string | null): string {
	if (!value) {
		return "";
	}

	if (!value.startsWith("http")){
		value = `https://${value}`;
	}

	return new URL(value).host?.replace("www.", "") ?? "";
}

export function copyIdClipboard(id: string | null) {
	if (!id) {
		return;
	}
	navigator.clipboard.writeText(id).then(
		() => {
			toast.success("ID Copied to Clipboard", {
				icon: "📋",
			});
			console.log("Async: Copying to clipboard was successful!");
		},
		function (err) {
			console.error("Async: Could not copy text: ", err);
		}
	);
}

export const dateFormatter = new Intl.DateTimeFormat("de-DE", {
	month: "2-digit",
	day: "2-digit",
});


export function calSum(items: number[]){
	return items.reduce((partialSum, a) => partialSum + a, 0)
}

export function formatWithDecimal(value: number): string | number {
	if (value > 1000) {
        return (value / 1000).toFixed(3);
    } else {
        return value;
    }
}
