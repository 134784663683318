import "../styles/globals.css";
import "../styles/swagger.css";
import "../styles/search-tariffs.css";
import type { AppProps } from "next/app";
import Layout from "../components/layout";
import { AuthContextProvider } from "../contexts/authContext";
import { createTheme, NextUIProvider } from "@nextui-org/react";
import Head from "next/head";
import { useSSR } from "@nextui-org/react";
export const primaryColor = "#f15930";
export const primaryHoverColor = "#f15930bf";

const theme = createTheme({
	type: "light", // it could be "light" or "dark"
	theme: {
		colors: {
			primary: primaryColor,
			secondary: "#ede7d5",
			primaryBorder: "$green500",
			primaryBorderHover: "$green600",
			primarySolidContrast: "white",
			navbarBackgroundColor: "white",
			primarySolidHover: primaryHoverColor,
			primaryLightActive: primaryHoverColor,
			primaryLight: primaryHoverColor,
			primaryLightContrast: "#fff",
			code: "white",
			primaryShadow: "$green500",
			link: primaryColor,
			text: "#000",
			background: "#ebebeb",
		},
		breakpoints: {
			xs: 650,
			sm: 800,
			md: "1280px",
			lg: "1400px",
			xl: "1920px",
		},
		space: {},
		fonts: {},
		letterSpacings: { letterSpacing: 0.25 },
		lineHeights: {
			lg: 1.35,
			md: 1.25,
		},
	},
});

function MyApp({ Component, pageProps }: AppProps) {
	const { isBrowser } = useSSR();
	return (
		isBrowser && (
			<NextUIProvider theme={theme}>
				<AuthContextProvider>
					<Layout>
						<Head>
							<title>Ladefuchs Admin</title>
							<meta
								name="viewport"
								content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
							/>
						</Head>
						<Component {...pageProps} />
					</Layout>
				</AuthContextProvider>
			</NextUIProvider>
		)
	);
}

export default MyApp;
