import {
	Button,
	Card,
	Container,
	Input,
	Loading,
	Text,
} from "@nextui-org/react";
import { useRouter } from "next/router";
import { useContext, useState } from "react";
import { login } from "../functions/api";
import { AuthContext, User } from "../contexts/authContext";

import { useForm } from "react-hook-form";

import styles from "../styles/login.module.css";
import app from "../functions/app";
import { getCardVariant } from "../functions/util";

interface Inputs {
	username: string;
	password: string;
}

export const authKey = "auth";

export default function Login() {
	const router = useRouter();
	const context = useContext(AuthContext);

	const { register, handleSubmit, getValues } = useForm<Inputs>();

	const [error, setError] = useState<null | string>(null);

	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async () => {
		setIsLoading(true);
		context.setUser(null);
		const { username, password } = getValues();
		try {
			const json = await login(username, password);
			setError(null);
			const user: User | null = json?.data;
			if (user) {
				localStorage.setItem("username", user.username);
				context.setUser(user.username);

				router.push(app.routes.dashboard.path);
			}
		} catch (error: any) {
			const message = error?.response?.data?.reason ?? error.message;
			console.error({ error });
			setError(message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Container
			className={styles.loginContainer}
			alignContent="center"
			display="flex"
			justify="center"
		>
			<Card className={styles.loginCard} variant={getCardVariant()}>
				<Card.Header>
					<Text h2>Login</Text>
				</Card.Header>
				<Card.Body css={{ paddingTop: 0 }}>
					{error && (
						<Text color="error" weight="bold">
							{error}
						</Text>
					)}
					<form
						onSubmit={handleSubmit(onSubmit)}
						className={styles.form}
					>
						<Input
							autoFocus
							label="Username"
							aria-label="username"
							css={{ marginBottom: "1rem" }}
							required
							size="md"
							clearable
							tabIndex={1}
							animated={false}
							bordered
							{...register("username")}
						/>
						<Input.Password
							aria-label="Password"
							required
							animated={false}
							label="Password"
							tabIndex={2}
							bordered
							className={styles.formLast}
							{...register("password")}
						/>
						<Button
							type="submit"
							tabIndex={3}
							disabled={isLoading}
							css={{ width: "100%" }}
						>
							{!isLoading && "Sign In"}
							{isLoading && (
								<Loading
									css={{
										paddingLeft: "1em",
										padding: "0.5em",
									}}
									type="points"
									color="currentColor"
									size="md"
								/>
							)}
						</Button>
					</form>
				</Card.Body>
			</Card>
		</Container>
	);
}
