import { Toaster } from "react-hot-toast";
import AppNavbar from "./navbar";

export type Props = { children: React.ReactNode };

const Layout = ({ children }: Props): JSX.Element => {
	return (
		<>
			<AppNavbar />
			<main>{children}</main>
			<Toaster
				position="top-center"
				containerStyle={{ top: "90px" }}
				reverseOrder={false}
			/>
		</>
	);
};

export default Layout;
