export interface Tariff {
	id: number;
	relationshipId: string;
	slugName: string;
	url: string | null;
	notes: string;
	providerName: string;
	internalName: string;
	image?: TariffImage;
	updated: string;
	standard: boolean;
	overrideStandard: boolean;
	imageId: number | null,
	hide: boolean;
	monthlyFee: number;
	providerCustomerOnly: boolean;
}

export interface UpdateTariff {
	id: number;
	notes: string;
	overrideStandard: boolean;
	hide: boolean;
	url: string | null;
	imageId: number | null,
	internalName: string;
}

export interface TariffImage {
	filename: string;
	checksum: string;
}

export interface Operator {
	id: number;
	pubNetwork: string;
	network: string;
	standard: boolean;
	slugName: string;
	name: string;
	url: string | null;
	hide: boolean;
	updated: string;
	supportedTypes: Array<ChargeTypes>;
	powerAc: number;
	powerDc: number;
	ccsPlugCount: number;
	type2PlugCount: number;
	sumPlugCount: number;
}


export type ChargeTypes = "ac" | "dc";

export interface PowerType {
	expect: number;
	power: number;
}

export interface ClicksPerDay {
	day: number;
	clicks: number;
}

export interface ThgClickSummary {
	lastThirtyDays: number;
	lastSevenDays: number;
	averageWeekly: number;
	total: number;
	totalByPlatform: PlatformDistribution;
}

export interface PlatformDistribution {
	android: number;
	ios: number;
	web: number;
}

export interface ImportResult {
	prices?: number | null;
	lastImport: string | null;
	nextImport?: string | null;
}

export interface AdminImportResult {
	status: ImportStatus;
	importResult: ImportResult | null;
	error?: string | null;
}

export enum ImportStatus {
	Waiting = "waiting",
	InProgress = "inProgress",
	Failure = "Failure",
}

export interface AppMetricsResponse{
	usageByPlatform: AppUsageByPlatform,
	usageGroupByDay: AppUsageByPlatformAndDay[]
	totalBannerImpression: number
}

export interface AppUsageByPlatform{
	ios: number
	android: number,
	total: number,
}

export interface AppUsageByPlatformAndDay extends AppUsageByPlatform{
	visitDate: string
}