import { Link, Navbar, Text } from "@nextui-org/react";
import Image from "next/image";
import { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/authContext";
import { logout } from "../functions/api";
import styles from "../styles/navbar.module.css";

import { useRouter } from "next/router";
import app from "../functions/app";
import { authKey } from "../pages/login";
import { useCookies } from "react-cookie";

import ladefuchsLogo from "../public/ladefuchs-logo.svg";

const AppNavbar = (): JSX.Element => {
	const context = useContext(AuthContext);
	const router = useRouter();

	const [cookie, _setCookie] = useCookies([authKey]);

	useEffect(() => {
		const username = localStorage.getItem("username");

		if (!context.user && username && cookie[authKey]) {
			context.setUser(username);
		}
	}, [context.user, cookie[authKey]]);

	const onLogout = async () => {
		await logout();
		context.setUser(null);
		localStorage.setItem("username", "");
		router.push(app.routes.login.path);
	};

	const showLinks = () => {
		return context.user && router.pathname !== app.routes.login.path;
	};

	const isActive = (route: string): boolean => {
		return router.pathname.startsWith(route);
	};

	const menuItems = () => {
		return Object.values(app.routes).filter((item) => !item.hide);
	};

	const renderMenuDesktopItems = (): JSX.Element[] => {
		return menuItems().map((item) => {
			return (
				<Navbar.Link
					key={item.path}
					href={item.path}
					isActive={isActive(item.path)}
					className={styles.navbarLink}
				>
					{item.label}
				</Navbar.Link>
			);
		});
	};

	const renderMenuMobileItems = (): JSX.Element[] => {
		return menuItems().map((item) => {
			return (
				<Navbar.CollapseItem key={item.path}>
					<Link href={item.path} color="text">
						<Text b size="$lg">
							{item.label}
						</Text>
					</Link>
				</Navbar.CollapseItem>
			);
		});
	};

	return (
		<Navbar
			isBordered
			variant="sticky"
			maxWidth="fluid"
			disableShadow={true}
			className={styles.navbar}
		>
			<Navbar.Brand css={{ paddingLeft: "1.5rem" }}>
				{/* <Lottie
					animationData={fuchsAnimation}
					loop={true}
					height={64}
					width={64}
				/> */}

				<Image
					src={ladefuchsLogo}
					height={64}
					width={64}
					style={{ height: 64, width: 64 }}
					alt={"logo"}
				/>
				<Text
					className="title"
					h1
					size={32}
					css={{
						textGradient: "50deg, $yellow600 -40%, #f52424 90%",
						margin: 0,
						marginLeft: "0.5em",
					}}
					weight="bold"
				>
					Ladefuchs Admin
				</Text>
			</Navbar.Brand>
			{showLinks() && (
				<>
					<Navbar.Content
						hideIn="md"
						activeColor="primary"
						gap={2}
						variant="underline-rounded"
					>
						{renderMenuDesktopItems()}
						<Navbar.Link
							onClick={onLogout}
							className={styles.navbarLink}
						>
							Logout
						</Navbar.Link>
					</Navbar.Content>
					<Navbar.Collapse
						className={styles.navbarCollapse}
						disableBlur={true}
					>
						{renderMenuMobileItems()}
						<Navbar.CollapseItem>
							<Link onClick={onLogout}>
								<Text b size="$lg">
									Logout
								</Text>
							</Link>
						</Navbar.CollapseItem>
					</Navbar.Collapse>
					<Navbar.Toggle
						showIn="md"
						hidden={!context.user}
						css={{ paddingRight: "2rem" }}
					/>
				</>
			)}
		</Navbar>
	);
};

export default AppNavbar;
